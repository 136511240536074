/* Use Material theming functions */

@use '@angular/material' as mat;
@use './colors' as theme;

html:not(.dark-theme) {
  color-scheme: light;
  @include mat.theme((
    color: (
      theme-type: light,
      primary: theme.$primary-palette,
      tertiary: theme.$tertiary-palette,
    ),
    typography: Roboto,
    density: 0,
  ));
}


html.dark-theme {
  color-scheme: dark;
  @include mat.theme((
    color: (
      theme-type: dark,
      primary: theme.$primary-palette,
    ),
    typography: Roboto,
    density: 0,
  ));
}

:root {
  @include mat.toolbar-overrides((
    container-background-color: var(--mat-sys-tertiary),
    container-text-color: var(--mat-sys-on-tertiary),
  ));

  @include mat.card-overrides((
    elevated-container-color: white,
  ));

  @include mat.form-field-overrides((
    filled-container-color: transparent,
    filled-label-text-color: var(--mat-sys-secondary),
  ));
}

// MatTable related adjustments

.mat-mdc-table,
.mat-mdc-paginator {
  background-color: transparent;
}

.mat-mdc-row:hover {
  background-color: var(--mat-sys-on-primary);
}
