/* Use app theming */

@use './theme';

/* You can add global styles to this file, and also import other style files */

//@import '~quill/dist/quill.snow.css';

html {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
}

/* Modern browsers with `scrollbar-*` support */

/* Legacy browsers with `::-webkit-scrollbar-*` support */
/*
@supports selector(::-webkit-scrollbar) {
  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.2);
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar {
    max-width: 0.5rem;
    max-height: 0.5rem;
    z-index: 1000;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
  }
}
*/
.header-content {
  width: 60rem;
}

.page-content {
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.mat-cell:first-of-type,
.mat-header-cell:first-of-type,
.mat-footer-cell:first-of-type {
  padding-left: 0px;
}

.mat-cell:last-of-type,
.mat-header-cell:last-of-type,
.mat-footer-cell:last-of-type {
  padding-right: 0px;
}

p {
  margin-block-start: 0px;
}

h2,
h3,
h4 {
  font-weight: 400;
}

li {
  margin-block-end: 0.5rem;

  :last-of-type {
    margin-block-end: 0px;
  }
}

mat-card {
  margin: 1rem;
  width: 20rem;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
}

mat-card-content {
  margin-top: 1rem;
}

.mat-cell:first-of-type,
.mat-header-cell:first-of-type,
.mat-footer-cell:first-of-type {
  padding-left: 0px;
}

.mat-cell:last-of-type,
.mat-header-cell:last-of-type,
.mat-footer-cell:last-of-type {
  padding-right: 0px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

[routerLink] {
  cursor: pointer;
}
